import React, {useState, useEffect, useRef} from "react";
import { useEntityInsertionById, wrapUserConsumer } from "../components/user-context.js"
import { Link } from "gatsby"
import { hasUnreadMessages } from "../components/conversations"

import {Badge, ListGroupItemText, ListGroupItemHeading, ListGroup, ListGroupItem, Card, CardBody, Container, Row, Col, Form, FormGroup, Input, Collapse, Button} from 'reactstrap';

import {
  siteMetadata
} from "../../gatsby-config"

/*

  fix dates being off by one month
  delete conversation option for mentors
  double check all permissions
  double check content type default values
  create link to conversations page in user dropdown
  Notification to policy builder that they have new messages (haven't thought about solutions yet)
  Styling
  order conversations by newest (maybe)
  order messages by newest (maybe)
  handle language. When displaying things like section title we need to use the appropriate title
  Change section link to say Open and move it to be next to the send button
  ~Make it clear who is part of the conversation and what section it's referring to~
  ~Displaying time for each message (used created date and formatted)~
  ~Linking section to the section page from the related section reference in the message data~
  ~Limit text input on new messages so we don't break the site (used Input attribute for max lenght and added a character count)~
  ~Display section title along with link~
  ~Display mentors name in conversation~
  ~Display the other user in the conversation, if mentor is logged in, display policy builders name, vice versa~
  ~change the dropdown button in the conversations list to display something different than the test title and uid~

*/

/*
  @param {messages} This is every message that the {user} has access to see
  @param {conversation} The specific conversation we're rendering
  @param {user} The user-context object

*/
function Messages({conversation, messages, user, includedUsers}){

  const conversationCreator = conversation.hasOwnProperty('relationships') && conversation.relationships.hasOwnProperty('uid') ? conversation.relationships.uid.data.id : null;
  //reader is always the person who was associated with the conversation when it was created (policy builder)
  const conversationReader = conversation.hasOwnProperty('relationships') && conversation.relationships.hasOwnProperty('field_user_permission') && conversation.relationships.field_user_permission.data !== null ? conversation.relationships.field_user_permission.data.id : null;
  const currentUser = user && user.hasOwnProperty('userData') && user.userData.hasOwnProperty('profile') ? Object.keys(user.userData.profile)[0] : null;


  const conversationCreatorColor = "success"
  const conversationReaderColor = "info";

  //Since we're given all messages a user can see, we just filter out only the ones that are related to the current conversation
  const relatedMessages = Object.entries(messages).filter( ([key, value]) => {
    return value.hasOwnProperty('relationships') ? value.relationships.field_related_conversation.data.id == conversation.id : false;
  }).map( message => {
    return message[1];
  });

  return (
    <ListGroup>
      {relatedMessages.map( message => {

        const createdDate = message.hasOwnProperty('attributes') && message.attributes.hasOwnProperty('created') ? new Date(message.attributes.created) : new Date();
        const dateString = createdDate.getFullYear() + "-" + (createdDate.getMonth()+1) + "-" + createdDate.getDate() + " " + createdDate.getHours() + ":" + createdDate.getMinutes();

        //messageCreator is always the person who created the invdividual message
        const messageCreator = message.hasOwnProperty('relationships') && message.relationships.hasOwnProperty('uid') ? message.relationships.uid.data.id : '';

        const messageCreatorName = includedUsers.hasOwnProperty(messageCreator) && includedUsers[messageCreator].attributes.display_name;

        //If there is no messageCreator that means the current user is the one who created the message since the message is in localstorage only
        const messageStyle = currentUser == messageCreator || messageCreator == '' ? conversationCreatorColor : conversationReaderColor;

        return (
          <ListGroupItem className="my-1" key={message.id} color={messageStyle}>
            <ListGroupItemHeading>{messageCreatorName}</ListGroupItemHeading>
            <ListGroupItemText>
              {message.attributes.field_message_text}
            </ListGroupItemText>
            <ListGroupItemText>
              {dateString}
            </ListGroupItemText>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

/*
  @param {conversation} The conversation the message is meant for
  @param {user} The user-context object
*/
function ManageMessage({conversation, messages, user}){

  const [inputText, setInputText] = useState("");

  //This is the limit on the content type but we don't really have a way to get it from drupal IIRC
  const MAX_MESSAGE_SIZE = 255;

  //creator is always the person who created the conversation (mentor)
  const creator = conversation.hasOwnProperty('relationships') && conversation.relationships.hasOwnProperty('uid') ? conversation.relationships.uid.data.id : null;
  //reader is always the person who was associated with the conversation when it was created (policy builder)
  const reader = conversation.hasOwnProperty('relationships') && conversation.relationships.hasOwnProperty('field_user_permission') && conversation.relationships.field_user_permission.data !== null ? conversation.relationships.field_user_permission.data.id : null;
  const currentUser = user && user.hasOwnProperty('userData') && user.userData.hasOwnProperty('profile') ? Object.keys(user.userData.profile)[0] : null;

  const currentUserProfileInclude = currentUser && user.userData.profile[currentUser].hasOwnProperty('included') ? user.userData.profile[currentUser].included : {};
  const hasMentorRole = currentUserProfileInclude.reduce( (acc, value) => {
    if(value.type == 'user_role--user_role'){
      if(value.hasOwnProperty('attributes') && value.attributes.drupal_internal__id == 'mentor'){
        return true;
      }
    }
    return acc;
  },false);

  //If the current user is the creator, we want to give the reader permission to view the message
  //vice versa if the user is the reader, we want to give the creator permission to view
  //Which is what the newMessageRecipient gets used for
  const newMessageRecipient = creator == currentUser || creator == null ? reader : creator;


  let messageHandler = user && user.getEntityUpdateHandler();
  messageHandler.getLocalId();
  let messageBody = {
    data: {
      type: "node--message",
      attributes: {
        title: "Test message",
        field_message_text: ""
      },
      relationships: {
        field_user_permission: {
          data: {
            type: "user--user",
            id: newMessageRecipient,
            meta: {
              grant_public: false,
              grant_view: true,
              grant_update: true,
              grant_delete: true,
            }
          }
        },
        field_related_conversation: {
          data: {
            type: "node--conversation",
            id: conversation.id
          }
        }
      }
    }
  }

  /*
    This is called when the send button is pressed, it prevents a page reload
    and clears the input box so the user can send another message without manually
    deleting the old message
  */
  async function handleSubmit(event){
    event.preventDefault();
    const data = new FormData(event.target);
    const message = data.get('newMessage');
    setInputText("");
    if(message !== ""){
      messageBody.data.attributes.field_message_text = message;
      messageHandler.handle({
        body: messageBody,
        insertIntoUserData: useEntityInsertionById('message'),
        identifyingPropertyString: 'message.local_id',
        identifyingPropertyCondition: messageHandler.localId,
      });
    }
  }


  const messageDeleteHandler = user.getEntityUpdateHandler();
  const conversationDeleteHandler = user.getEntityUpdateHandler();
  /*
    this doesn't actually delete the conversation it just sets the published status to false
    which is fine since we
  */
  async function deleteConversation(event){
    event.preventDefault();
    if(messageDeleteHandler && conversationDeleteHandler){
      const relevantMessages = Object.values(messages).filter( message => {
        let messageConversationId = null;
        if(message.hasOwnProperty('relationships') &&
          message.relationships.hasOwnProperty('field_related_conversation') &&
          message.relationships.field_related_conversation.hasOwnProperty('data') &&
          message.relationships.field_related_conversation.data.hasOwnProperty('id')){
            messageConversationId = message.relationships.field_related_conversation.data.id;
        }
        return messageConversationId == conversation.id;
      });
      relevantMessages.map( message => {
        messageDeleteHandler.handle({
          body: {data: message},
          insertIntoUserData: useEntityInsertionById('message'),
          identifyingPropertyString: 'message.id',
          identifyingPropertyCondition: message.id,
        },true);
      });
      conversationDeleteHandler.handle({
          body: {data: conversation},
          insertIntoUserData: useEntityInsertionById('conversation'),
          identifyingPropertyString: 'conversation.id',
          identifyingPropertyCondition: conversation.id,
      },true);
    }

  }

  /*
    We track the input like this so we can remove it from the box once the message is sent
    This also lets us track the character count
  */
  async function inputTextChange(event){
    event.preventDefault();
    const message = event.target.value;
    setInputText(message);
  }

  let buttonDisplay;

  if(hasMentorRole){
    buttonDisplay = (
      <>
        <Row>
          <Col><div className="text-right">{inputText.length}/{MAX_MESSAGE_SIZE}</div></Col>
        </Row>
        <Row>
          <Col><Button color="success">Send</Button></Col>
          <Col><Button onClick={deleteConversation} color="danger" className="float-right">End Conversation</Button></Col>
        </Row>
      </>
    );
  }else{
    buttonDisplay = (
      <>
        <Row>
          <Col><Button color="success">Send</Button></Col>
          <Col><div className="text-right">{inputText.length}/{MAX_MESSAGE_SIZE}</div></Col>
        </Row>
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit} >
      <Input value={inputText} onChange={inputTextChange} maxLength={MAX_MESSAGE_SIZE} placeholder="Type new message" type="textarea" name="newMessage" id="newMessage" />
      {buttonDisplay}
    </Form>
  );
}

function Conversation({state, data, conversation, messages, user, includedUsers}){

  const localConversationId = state && state.hasOwnProperty('localConversationID') ? state.localConversationID : null;
  const isLocalConversation = conversation.hasOwnProperty('local_id') && conversation.local_id == localConversationId;
  const [isOpen, setIsOpen] = useState(isLocalConversation);

  const creator = conversation.hasOwnProperty('relationships') && conversation.relationships.hasOwnProperty('uid') ? conversation.relationships.uid.data.id : null;
  const reader = conversation.hasOwnProperty('relationships') && conversation.relationships.hasOwnProperty('field_user_permission') && conversation.relationships.field_user_permission.data !== null ? conversation.relationships.field_user_permission.data.id : null;

  let creatorName = includedUsers.hasOwnProperty(creator) ? includedUsers[creator].attributes.display_name : '';
  let readerName = includedUsers.hasOwnProperty(reader) ? includedUsers[reader].attributes.display_name : '';

  const readerNameFromState = state && state.hasOwnProperty('name') ? state.name : '';

  readerName = readerName == '' ? readerNameFromState : readerName;

  const currentUser = user && user.hasOwnProperty('userData') && user.userData.hasOwnProperty('profile') ? Object.keys(user.userData.profile)[0] : null;
  const currentUserName = currentUser && user.userData.profile.hasOwnProperty(currentUser) ? user.userData.profile[currentUser].data.attributes.display_name : '';

  creatorName = creatorName ? creatorName : currentUserName;

  const sectionId = conversation.relationships.field_related_section.data !== null ? conversation.relationships.field_related_section.data.id : null;

  let sectionData;
  for(let node in data.allNodeSection){
    sectionData = data.allNodeSection[node].filter( node => {
      return node.drupal_id == sectionId;
    });
  }

  const sectionTitle = sectionData[0].title;
  const sectionPath = sectionData[0].path.alias;
  const chapterPath = sectionData[0].relationships.node__chapter[0].path.alias;
  const fullSectionPath = chapterPath + sectionPath;

  const conversationId = conversation && conversation.hasOwnProperty('id') ? conversation.id : null;
  //also need to make sure the current user is the reader of the message and the message is for this conversation
  const relevantMessages = Object.values(messages).filter( message => {
    let messageConversationId = null;
    if(message.hasOwnProperty('relationships') &&
      message.relationships.hasOwnProperty('field_related_conversation') &&
      message.relationships.field_related_conversation.hasOwnProperty('data') &&
      message.relationships.field_related_conversation.data.hasOwnProperty('id')){
        messageConversationId = message.relationships.field_related_conversation.data.id;
    }
    let messageReader = null;
    if(message.hasOwnProperty('relationships') &&
      message.relationships.hasOwnProperty('field_user_permission') &&
      message.relationships.field_user_permission.hasOwnProperty('data') &&
      message.relationships.field_user_permission.data.hasOwnProperty('id')){
        messageReader = message.relationships.field_user_permission.data.id;
    }
    return messageReader == currentUser && messageConversationId == conversationId;
  });

  const unreadMessageCount = hasUnreadMessages(relevantMessages, currentUser);
  const messageNotification = unreadMessageCount > 0 ? <Badge color="danger">{unreadMessageCount}</Badge> : null;


  let messageHandler = user && user.getEntityUpdateHandler();
  const toggle = () => {
    setIsOpen(!isOpen);
    if(messageHandler && unreadMessageCount > 0){
      relevantMessages.map( message => {
        if(!message.attributes.field_message_read){
          message.attributes.field_message_read = true;
          const messageBody = {data: {type: "node--message", attributes: {field_message_read: true}}};
          messageHandler.handle({
            body: messageBody,
            identifyingPropertyString: 'message.id',
            identifyingPropertyCondition: message.id
          });
        }
      });
    }
  }

  const classes = isOpen ? "btn-lg btn-secondary text-center" : "btn-lg btn-secondary text-center";
  const itemStyle = isOpen ? {backgroundColor: "#f2f2f2"} : {};

  return (
    <ListGroupItem className="my-1" style={itemStyle}>
      <div style={{cursor: "pointer"}} className={classes} onClick={toggle}>Conversation between {creatorName} and {readerName} about {sectionTitle} {messageNotification}</div>
      <Collapse isOpen={isOpen}>
        <div><Link style={{backgroundColor: "#e6ffff"}} className="my-1 text-left btn-block btn btn-lg btn-secondary" to={fullSectionPath}><i className="ni ni-books"></i>{sectionTitle}</Link></div>
        <Messages conversation={conversation} messages={messages} user={user} includedUsers={includedUsers}/>
        <ManageMessage messages={messages} conversation={conversation} user={user} />
      </Collapse>
    </ListGroupItem>
  );
}

function Conversations({state, data, conversations, messages, user, includedUsers}){
  return(
    <Container fluid="sm">
      <Row>
        <Col className="mx-auto" sm={{ size: 6, order: 2, offset: 1 }}>
          <ListGroup>
            {Object.entries(conversations).map( ([key, value]) => {
              return (
                <Conversation state={state} data={data} conversation={value} messages={messages} user={user} includedUsers={includedUsers}/>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

function ConversationsPage({data, user, location}){

  const conversations = user && user.userData.hasOwnProperty('conversation') ? user.userData.conversation : {};
  const messages = user && user.userData.hasOwnProperty('message') ? user.userData.message : {};
  const includedUsers = user && user.userData.hasOwnProperty('user') ? user.userData.user : {};

  let display = null;
  if(Object.keys(conversations).length > 0){
    display = (
      <Container className="mt--6" fluid>
        <Card>
          <CardBody>
            <Conversations state={location.state} data={data} conversations={conversations} messages={messages} user={user} includedUsers={includedUsers}/>
          </CardBody>
        </Card>
      </Container>
    );
  }else{
    display = (
      <Container className="mt--6" fluid>
        <Card>
          <CardBody>
            <div className="text-center">There are currently no open conversations. If you have any questions about this feature please contact your mentor.</div>
          </CardBody>
        </Card>
      </Container>
    );
  }

  return display;
}


export const query = graphql`
  query ConversationsQuery {
    allNodeSection(filter: {queriedLanguage: {eq: "en"}}) {
      nodes {
        title
        drupal_id
        path {
          alias
        }
        relationships {
          node__chapter {
            path {
              alias
            }
          }
        }
      }
    }
  }
`;

export default wrapUserConsumer(ConversationsPage);
